const settings = {
  //                         xs,    sm,    md,    lg,    xl,    xxl          <-- only sample, check your breakpoints settings.
  //                         <      576    768    992    1200   1600
  breakpointNames:          ['xs', 'sm',  'md',  'lg',  'xl',  'xxl'],
  numOfTilesPerBreakpoints: [2,     2,     2,     3,     4,     6],
  marginLR:                 [14,    20,    20,    20,    20,    20],
  marginTB:                 [14,    20,    20,    20,    20,    20],
}

const bigSettings = {
  //                         xs,    sm,    md,    lg,    xl,    xxl          <-- only sample, check your breakpoints settings.
  //                         <      576    768    992    1200   1600
  breakpointNames:          ['xs', 'sm',  'md',  'lg',  'xl',  'xxl'],
  numOfTilesPerBreakpoints: [2,     2,     2,     3,     4,     4],
  marginLR:                 [8,    8,    8,    8,    8,    8],
  marginTB:                 [8,    8,    8,    8,    8,    8],
}

exports.settings = settings;
exports.bigSettings = bigSettings;