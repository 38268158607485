const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors ={
  darkGrayColor : '#23272B',
  lightGrayColor : '#EBEBEB',
  redColor : '#B61218',
  darkRedColor : '#A61016'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"anisette-std",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.darkGrayColor,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;