/** @jsx jsx */
import { jsx } from '@emotion/core'
import Slider from "react-slick";
import { settings as defaultSettings} from './config';

/**
 * Tile List
 * @param {Array<JSX.Element>} tiles React elements, required
 * @param {Object} style Style object, required
 * @param {string} cssClass
 * @param {string} useSlider Use SlickSlider, default: false
 * @param {Object} settings Tile Settings, required
 * @param {...Object} attrs Other attributes in the list level
 */
export function TileList({tiles, style, overridingStyle, cssClass, useSlider = false, settings = defaultSettings, ...attrs}) {
  const s = style || {};
  const ss = overridingStyle || {};
  const slickSettings = settings.slickSettings;
  
  useSlider = useSlider && tiles.length > 1
  slickSettings.infinite = tiles.length > 2

  if (useSlider) {
    return (
      <div className={cssClass} css={[s.holder, ss.holder]} {...attrs}>
        <div css={[s.tiles, ss.tiles, useSlider && s.tilesSlider, useSlider && ss.tilesSlider]}>
          <Slider {...slickSettings} >
            {tiles}
          </Slider>
        </div>
      </div>
    );
  } else {
    return (
      <div className={cssClass} css={[s.holder, ss.holder]} {...attrs}>
        <div css={[s.tiles, ss.tiles]}>
          {tiles}
        </div>
      </div>
    );
  }

}