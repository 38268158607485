import { mq } from '../../cssInJs';
import { settings as defaultSettings} from '../tileList/config';

export function getImageTileStyle(settings = defaultSettings) {
  const numOfTilesPerBreakpoints = settings.numOfTilesPerBreakpoints
  const marginLR = settings.marginLR
  const marginTB = settings.marginTB

  return {
    tile: mq({
      paddingLeft: marginLR.map(x => x === null? null : x / 2),
      paddingRight: marginLR.map(x => x === null? null : x / 2),
      paddingTop: marginTB.map(x => x === null? null : x / 2),
      paddingBottom: marginTB.map(x => x === null? null : x / 2),
      maxWidth: numOfTilesPerBreakpoints.map(x => x === null? null : 100 / x + '%'),
      flexBasis: numOfTilesPerBreakpoints.map(x => x === null? null : 100 / x + '%'),
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      color: 'inherit',
      ':hover': {
        textDecoration: 'none',
        color: 'inherit',
        '.tileBg': {
          transform: 'scale(1.02)',
          transition: 'all .2s ease-in-out'
        }
      },
      '&.gallery-big-tile' : {
        '.tileBg' : {
          height: '40vw',
          maxHeight: '256px',
          paddingBottom : 'unset'
        }
      }
    }),
    tileSlider: mq({
      maxWidth: '100% !important'
    }),
    tileBg: mq({
      backgroundColor: 'white',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: 0,
      paddingBottom: '100%',//'56.25%',
    }),
  }
}

