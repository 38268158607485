import React, { Fragment } from 'react';
import { Item } from '../../items/item';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import Slider from "react-slick";
import { TopHeader } from '../../../layout/topHeader/topHeader';

export function BannerPanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const type = props.type || ""; // menu

  //console.log('item', item, subItems);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';

  const slides = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const cssClass = utils.classNames('cms_item', 'bannerPanel', `bannerWrapperSize--${heightSet}`, item.cssClass || item.anchorName || '');
  return (
    <Fragment>
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
        <Slider {...settings} >
          {slides}
        </Slider>
      </div>
      {
        type === "menu" &&
        <TopHeader type="menu"></TopHeader>
      }
    </Fragment>
  );
}