/** @jsx jsx */
import { jsx } from "@emotion/core";
import utils from "../../utils";
import { SiteLink } from "../../components";

import { TileList } from "../tileList/tileList";
import { getTileListStyle } from "../tileList/tileListStyle";
import { settings, bigSettings } from "./config";

import { getImageTileStyle } from "./galleryStyle";

export function GalleryTiles({
  images,
  openPopupImage,
  useSlider = false,
  cssClass,
  type = "normal",
}) {
  if (images.length === 0) return null;
  const tiles = images.map((img, index) => (
    <ImageTile
      key={index}
      image={img}
      useSlider={useSlider}
      openPopupImage={openPopupImage}
      type={type}
    />
  ));
  return (
    <TileList
      tiles={tiles}
      style={getTileListStyle(type !== "normal" ? bigSettings : settings)}
      useSlider={useSlider}
      cssClass={cssClass}
    />
  );
}

function ImageTile({
  image,
  useSlider,
  disableLink,
  openPopupImage,
  type = "normal",
}) {
  const s = getImageTileStyle(type !== "normal" ? bigSettings : settings);
  //const imageUrl = utils.site.resourcePath(image.imageUrl);
  const thumbnailUrl = type === 'normal' ? (image.thumbnailUrl || image.imageUrl) : image.imageUrl;
  //const thumbnailUrl = "https://pavilionatprospect.com.au/sites/pavilionatprospect/media/banners/function-hall-10.jpg";
  const tileLink = image.imageUrl;

  return (
    <SiteLink
      className={type === "big" ? "gallery-big-tile" : ""}
      css={[s.tile, useSlider && s.tileSlider]}
      to={disableLink ? "" : tileLink}
      onClick={(e) => openPopupImage(e, image)}
    >
      <div style={{overflow : 'hidden'}}>
        <div
          css={s.tileBg}
          className="tileBg"
          style={{ backgroundImage: utils.css.bgUrlStyle(thumbnailUrl) }}
        ></div>
      </div>
    </SiteLink>
  );
}
